import type { ListRoutes } from "~/interfaces/routes";

export const useBackLink = () => {
  const runtimeConfig = useRuntimeConfig();
  const route = useRoute();

  const getBackLink = (bundle?: string, teamType?: string) => {
    if (!bundle) {
      bundle = `${route.meta?.bundle}_list`;
    }

    const listRoutes = runtimeConfig.public.listRoutes as unknown as ListRoutes;

    if (bundle in listRoutes) {
      if (teamType) {
        const teamRoute = listRoutes[bundle].find((route) => route.fieldTeamType === teamType);
        return teamRoute?.pathRawField?.list[0]?.alias ?? null;
      }

      return listRoutes[bundle][0]?.pathRawField?.list[0]?.alias ?? null;
    }

    return null;
  };

  return {
    getBackLink,
  };
};
